var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { ref: "template" },
    [
      _c("a-time-picker", {
        style: "width:" + _vm.options.width,
        attrs: {
          disabled: _vm.options.disabled || _vm.parentDisabled,
          "allow-clear": _vm.options.clearable,
          placeholder: _vm.options.placeholder,
          format: _vm.options.format,
          "value-format": _vm.options.valueFormat,
          value: _vm.time,
          "default-value": _vm.defaultValue,
          "start-disabled": _vm.startDisabled,
          "end-disabled": _vm.endDisabled,
          "disabled-hours": _vm.getDisabledHours,
          "disabled-minutes": _vm.getDisabledMinutes,
          "default-open-value": _vm.moment("00:00", "HH:mm"),
          "get-popup-container": function () {
            return _vm.$refs.template
          },
        },
        on: { change: _vm.handleSelectChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }